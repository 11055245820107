import { getModule, request, validation } from '@lighthouse/sdk'
import cuid from 'cuid'
import { get, isBoolean, merge, reduce, trim } from 'lodash'
import React, { Fragment, useState } from 'react'
import compose from 'recompose/compose'
import {
  reduxForm,
  Field,
  FieldArray,
  change,
  formValueSelector,
} from 'redux-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { useMount } from 'react-use'

import { Crud } from 'components/controls'
import { Block, Flex, Wrapper } from 'components/common'
import { handleFormError } from 'components/form/helpers'
import TableSpinner from 'components/table-next/table-spinner'
import { TOOLTIPS } from 'config/constants'
import colors from 'config/theme/colors'

import {
  InputSelect,
  InputText,
  BasicForm,
  InputGroup,
  InputLabel,
  InputNumber,
  FieldGroup,
  FieldSet,
  Select,
} from 'components/form'

import TitleBar from 'modules/title-bar'
import Button from 'components/button'
import ButtonGroup from 'components/button-group'
import Alert from 'components/alert'
import Icon from 'components/icon'
import * as expiryDurationOptions from './expiryDurationOptions'
import { Application, ExpiryDurationUnit } from '../applications.types'
import ApplicationSelect from '../../components/application-select/application-select'
import FlagFormTable from '../../../components/flag-list-form/FlagListForm'
import flags from '../../../components/flag-list-form/flags'
import PluginFormTable from '../../../components/plugin-list-form/PluginListForm'
import winteamOptions from '../../../components/plugin-list-form/winteamOptions'
import quicksightOptions from '../../../components/plugin-list-form/quicksightOptions'
import dayforceOptions from '../../../components/plugin-list-form/dayforceOptions'
import timegateOptions from '../../../components/plugin-list-form/timegateOptions'

import i18next from 'i18next'
import { useRequest } from 'components/useRequest'

const userApplicationsModule = getModule('userApplications')

const booleanOptions = [
  { label: 'On', value: true },
  { label: 'Off', value: false },
]

const defaultFlags = reduce(
  flags,
  (accum, flag) => {
    accum[flag.fieldName] = flag.default
    return accum
  },
  {}
)

const shiftSettingOptions = [
  { label: 'Automatically', value: true },
  { label: 'Manually', value: false },
]

const defaultSettings = {
  location: {
    adhoc: { enabled: true, options: { desiredAccuracy: 25 } },
    passive: { enabled: true },
  },
  locationLimit: 1000,
  maxVideoSize: 120,
  userLimit: 1000,
  userAutoStartShift: true,
  userAutoEndShift: true,
  welcomeQuotes: [],
}

const defaultWinteamOptions = {
  enabled: false,
  options: {
    teamtime: {
      enabled: false,
    },
    schedule: {
      enabled: false,
    },
  },
}

const defaultPlugins = {
  winteam: defaultWinteamOptions,
}

const defaultInitialValues = {
  flags: defaultFlags,
  settings: defaultSettings,
  expiryDurationConfig: {
    days: 1,
    hours: 1,
    minutes: 5,
    months: 3,
    unit: ExpiryDurationUnit.Month,
    weeks: 1,
  },
  plugins: defaultPlugins,
}

const isRequiredFn = validation.isRequired()
const isRequired = value =>
  isRequiredFn(value) ? i18next.t('validation.requiredField') : undefined
const isNumber = validation.isNumber()
const isLength = max => validation.isLength({ max })

const FORM_NAME = 'create-application'

const TOOLTIP_ADHOC = [TOOLTIPS.adhoc.titleT, TOOLTIPS.adhoc.messageT]

const TOOLTIP_PASSIVE = [TOOLTIPS.passive.titleT, TOOLTIPS.passive.messageT]

function RenderQuoteFields({ fields }): JSX.Element {
  const { t } = useTranslation()
  const formGroups = fields.map((path, index) => {
    return (
      <Fragment key={`welcomeQuoteField${index}`}>
        <Flex flex={1}>
          <Flex
            flex={1}
            flexDirection="column"
            paddingTop={index === 0 ? 0 : 20}
          >
            <Field
              component={InputText}
              label="Quote"
              name={`${path}.quote`}
              validate={[isLength(210)]}
            />
            <Field
              component={InputText}
              label="Author"
              name={`${path}.author`}
              validate={[isLength(30)]}
            />
          </Flex>
          <Flex alignItems="center" paddingLeft={20}>
            <Button
              onClick={() => fields.remove(index)}
              theme="simple noMargin"
            >
              <Icon name="trash" theme={{ fontSize: 20 }} />
            </Button>
          </Flex>
        </Flex>
        {index !== fields.length ? (
          <Flex
            borderBottomColor={colors.gray.lighter}
            borderBottomStyle="solid"
            borderBottomWidth={1}
          />
        ) : null}
      </Fragment>
    )
  })

  return (
    <>
      <FieldGroup title="Welcome Quotes">{formGroups}</FieldGroup>
      <Button
        dataTestId="add-quote-button"
        theme="simple"
        onClick={() =>
          fields.push({
            author: '',
            id: cuid(),
            quote: '',
          })
        }
      >
        {t('button.addQuote')}
      </Button>
    </>
  )
}

export default compose(
  withRouter,
  reduxForm({
    form: FORM_NAME,
    initialValues: defaultInitialValues,
    onChange: (values, dispatch) => {
      if (!values.plugins.winteam.enabled) {
        dispatch(
          change(FORM_NAME, 'plugins.winteam.options.teamtime.enabled', false)
        )
        dispatch(
          change(FORM_NAME, 'plugins.winteam.options.schedule.enabled', false)
        )
      }
    },
  })
)(ApplicationEntry)

function ApplicationEntry(props) {
  const {
    dirty,
    error,
    handleSubmit,
    history,
    match,
    initialize,
    reset,
    submitFailed,
    submitting,
  } = props

  const { request } = useRequest()

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const applicationName = useSelector(
    state => state.form[FORM_NAME].values.name
  )

  const pluginsWinteamEnabled = useSelector(state =>
    get(state.form[FORM_NAME].values, 'plugins.winteam.enabled')
  )

  const parsedWinteamOptions = winteamOptions.map(option => ({
    ...option,
    disabled: option.isSubOption && !pluginsWinteamEnabled ? true : false,
  }))

  const selector = formValueSelector(FORM_NAME)
  const signedUrlExpiryUnit = useSelector(state =>
    selector(state, 'expiryDurationConfig.unit')
  )

  const [isLoading, setLoading] = useState(false)
  const [requestError, setRequestError] = useState()

  const id = match.params && match.params.id

  useMount((): void => {
    const fetchData = async (): Promise<void> => {
      try {
        setLoading(true)
        setRequestError(null)

        if (id) {
          const response = await request<Application>(
            `/applications/${id}`,
            {},
            { scopeByApplication: false }
          )
          const application = response.json

          const expiryDurationConfig = getExpiryDurationConfig(application)
          const initialValues = merge(
            {},
            defaultInitialValues,
            application,
            expiryDurationConfig
          )

          // NOTE when an existing application is missing a default flag, we
          // should fallback to FALSE instead of the default value. Otherwise,
          // the default might show as TRUE when it's actually not set on the
          // application, causing confusion to the user. It would also change
          // behaviour once the application is saved if the user didn't
          // intentionally change a flag. E.g. converting a previously falsy
          // value to the default TRUE value
          const parsedFlags = reduce(
            initialValues.flags,
            (accum, flagValue, flagKey) => {
              const existingFlagValue = get(application, `flags.${flagKey}`)

              if (isBoolean(existingFlagValue)) {
                accum[flagKey] = existingFlagValue
                return accum
              }

              accum[flagKey] = false
              return accum
            },
            {}
          )

          initialValues.flags = parsedFlags

          return initialize(initialValues)
        }
      } catch (err) {
        setRequestError(err)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  })

  const breadcrumbs = [
    {
      title: 'Application',
      link: '/admin/applications',
    },
  ]

  const name = applicationName || 'New Application'

  const saveApplication = async data => {
    const {
      cloneApplicationId,
      flags,
      name,
      slug,
      settings,
      expiryDurationConfig,
      plugins,
    } = data
    settings.signedUrlExpiryDuration = getSignedUrlExpiryDuration(
      expiryDurationConfig
    )

    const payload = {
      name: trim(name),
      slug: trim(slug).toLowerCase(),
      flags,
      settings,
      clone: null,
      plugins,
    }

    if (cloneApplicationId) {
      payload.clone = {
        applicationId: cloneApplicationId,
        collections: ['audits', 'roles', 'templates'],
      }
    }

    const opts = {
      body: payload,
      method: id ? 'PUT' : 'POST',
    }

    const path = `/applications${id ? '/' + id : ''}`

    return request(path, opts, { scopeByApplication: false })
      .then(response => {
        reset()
        dispatch(userApplicationsModule.queryRequest())
        history.push('/admin/applications')
      })
      .catch(handleFormError)
  }

  if (requestError) {
    return (
      <Wrapper theme={{ height: 'auto' }}>
        <Alert type="error" messages={[requestError.message]} />
      </Wrapper>
    )
  }

  if (isLoading) {
    return (
      <Wrapper theme={{ height: 'auto' }}>
        <TableSpinner />
      </Wrapper>
    )
  }

  return (
    <Wrapper theme={{ height: 'auto' }}>
      {error && submitFailed && <Alert type="error" messages={[error]} />}
      <BasicForm noValidate>
        <TitleBar breadcrumbs={breadcrumbs} title={name} />
        <FieldGroup>
          <FieldSet>
            <Field
              component={InputText}
              label="Name"
              name="name"
              required
              validate={[isRequired]}
            />
            {!id && (
              <>
                <Field
                  component={InputText}
                  label="Slug"
                  name="slug"
                  required
                  validate={[isRequired]}
                />
                <ApplicationSelect
                  label="Clone App Setup"
                  name="cloneApplicationId"
                />
              </>
            )}
          </FieldSet>
        </FieldGroup>
        <FieldGroup
          title="Location Tracking Settings"
          description={t('labelLocationSettingsDescription')}
        >
          <Field
            component={InputSelect}
            label={t('labelAlwaysBackground')}
            name="settings.location.passive.enabled"
            options={booleanOptions}
            required
            tooltip={[t(TOOLTIP_PASSIVE[0]), t(TOOLTIP_PASSIVE[1])]}
            validate={[isRequired]}
          />
          <Field
            component={InputSelect}
            label={t('labelSpecificActions')}
            name="settings.location.adhoc.enabled"
            options={booleanOptions}
            required
            tooltip={[t(TOOLTIP_ADHOC[0]), t(TOOLTIP_ADHOC[1])]}
            validate={[isRequired]}
          />
          <Field
            component={InputNumber}
            label="Distance Sensitivity (m)"
            name="settings.location.adhoc.options.desiredAccuracy"
            validate={[isRequired, isNumber]}
          />
        </FieldGroup>
        <FieldGroup title="Other Settings">
          <FieldSet>
            <Field
              component={InputNumber}
              label="Users Limit"
              name="settings.userLimit"
              required
              validate={[isRequired, isNumber]}
            />
            <InputGroup>
              <InputLabel label="Users Start Shift" required />
              <Block width={200}>
                <Field
                  component={InputSelect}
                  name="settings.userAutoStartShift"
                  options={shiftSettingOptions}
                  required
                  validate={[isRequired]}
                />
              </Block>
            </InputGroup>
            <InputGroup>
              <InputLabel label="Users End Shift" required />
              <Block width={200}>
                <Field
                  component={InputSelect}
                  name="settings.userAutoEndShift"
                  options={shiftSettingOptions}
                  required
                  validate={[isRequired]}
                />
              </Block>
            </InputGroup>
            <Field
              component={InputNumber}
              label="Max Video Size (MB)"
              name="settings.maxVideoSize"
              required
              validate={[isRequired, isNumber]}
            />
            <Block marginBottom={20}>
              <InputGroup>
                <InputLabel label="Expiry time on URLs" required />
                <Block width={150}>
                  <Field
                    component={Select}
                    name="expiryDurationConfig.unit"
                    options={expiryDurationOptions.units}
                  />
                </Block>
                <Block paddingLeft={10} width={150}>
                  <Field
                    component={Select}
                    name={`expiryDurationConfig.${signedUrlExpiryUnit}`}
                    options={expiryDurationOptions[signedUrlExpiryUnit]}
                    required
                    value={expiryDurationOptions[signedUrlExpiryUnit]}
                  />
                </Block>
              </InputGroup>
            </Block>
          </FieldSet>
        </FieldGroup>
        <FieldGroup title="WinTeam">
          <PluginFormTable options={parsedWinteamOptions} />
        </FieldGroup>
        <FieldGroup title="Quicksight">
          <PluginFormTable options={quicksightOptions} />
        </FieldGroup>
        <FieldGroup title="Dayforce">
          <PluginFormTable options={dayforceOptions} />
        </FieldGroup>
        <FieldGroup title="TEAM by WorkWave / Timegate">
          <PluginFormTable options={timegateOptions} />
        </FieldGroup>
        <FieldGroup title="Flags">
          <FlagFormTable flags={flags} />
        </FieldGroup>
        <FieldArray
          component={RenderQuoteFields}
          name={'settings.welcomeQuotes'}
        />
        {dirty && (
          <ButtonGroup align="left">
            <Crud
              dirty={dirty}
              name={name}
              handleSave={handleSubmit(saveApplication)}
              reset={reset}
              submitting={submitting}
            />
          </ButtonGroup>
        )}
      </BasicForm>
    </Wrapper>
  )
}

function getExpiryDurationConfig(application) {
  const defaultExpiryDuration = { unit: 'months', value: 3 }
  const signedUrlExpiryDuration = get(
    application,
    'settings.signedUrlExpiryDuration',
    defaultExpiryDuration
  )
  const { unit, value } = signedUrlExpiryDuration
  const expiryDurationConfig = { unit, [unit]: value }
  return { expiryDurationConfig }
}

function getSignedUrlExpiryDuration(expiryDurationConfig) {
  const { unit } = expiryDurationConfig
  const value = expiryDurationConfig[unit]
  return { unit, value }
}
