import { noop } from 'lodash'
import React from 'react'
import styles from './styles'
import { useHistory } from 'react-router-dom'
import { Trans } from 'react-i18next'

import { map } from 'lodash'

interface AlertProps {
  i18nKey?: string
  italicFontStyle?: boolean
  link?: string
  messages: string[]
  small?: boolean
  type?: string
}

export default function Alert(props: AlertProps): JSX.Element {
  const {
    i18nKey,
    italicFontStyle = false,
    link = '',
    messages,
    small = false,
    type,
    ...styleProps
  } = props

  const history = useHistory()

  const listMessages = map(messages, (message: string, index: number) => {
    const listItemStyle = {
      cursor: link ? 'pointer' : 'auto',
      fontSize: small ? 12 : 13,
      fontStyle: italicFontStyle && index !== 0 ? 'italic' : 'normal',
      ...styles.item,
    }

    return (
      <li
        onClick={link ? (): void => history.push(link) : noop}
        style={listItemStyle}
        key={index}
      >
        {i18nKey ? (
          <Trans i18nKey={i18nKey} components={{ underline: <u /> }} />
        ) : (
          message
        )}
      </li>
    )
  })

  return (
    <ul style={{ ...styles.root, ...styles[type], ...styleProps }}>
      {listMessages}
    </ul>
  )
}
