import { get, noop } from 'lodash'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { getModule } from '@lighthouse/sdk'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import reactGa from 'react-ga'
import React, { Component } from 'react'

import * as logger from 'utils/logger'
import socket from 'utils/socket'
import { set, setUserId } from '../tracking'
import { WithPermissions } from '@lighthouse/react-components'

// Routes
import AdminRoute from '../routes/admin'
import MapsRoute from '../routes/maps'
import MessagesRoute from '../routes/messages'
import NotFoundRoute from '../routes/404'
import SetupRoute from '../routes/setup'
import ReportsRoute from '../routes/reports'
import TemplatesRoute from '../routes/templates'
import SchedulesRoute from '../routes/schedules'
import SettingsRoute from '../routes/settings'

// Modules
const activityModule = getModule('activity')
const appModule = getModule('app')
const areaModule = getModule('areas')
const authenticationModule = getModule('authentication')
const issueModule = getModule('issues')
const messagesModule = getModule('messages', 'messages')
const roomsModule = getModule('messages', 'rooms')
const speakersModule = getModule('messages', 'speakers')
const taskEntryModule = getModule('taskEntries')
const userApplicationsModule = getModule('userApplications')
const userModule = getModule('user')
const zonesModule = getModule('zones')

const ZONE_FIELDS = '_id,name,location'

// Components
import App from './components/app'
import FlagsHOC from 'components/flags/hoc'

import { AREA_FIELDS } from '../routes/maps/lib/constants'
import { configure as configureAmplify } from '../lib/amplify'

class AppContainer extends Component {
  state = {
    loading: false,
  }

  constructor() {
    super()

    this.handleActivity = this.handleActivity.bind(this)
    this.onSocketData = this.onSocketData.bind(this)
  }

  async componentDidMount() {
    const {
      currentApplication,
      currentUser,
      fetchLocations,
      fetchLocationGroups,
      fetchZones,
      hasLoadedAllLocations,
      hasLoadedAllZones,
      history,
      region,
    } = this.props

    window.pendo.initialize({
      visitor: {
        id: currentUser._id,
        name: currentUser.username,
      },
      account: {
        id: currentApplication.application._id,
        name: currentApplication.application.name,
      },
    })

    configureAmplify({
      regionCode: region,
    })

    if (hasLoadedAllLocations) {
      console.debug('All locations loaded, skipping requests')
    } else {
      console.debug('Fetching all locations...')
      const fields = AREA_FIELDS.join(',')
      // Fetch area locations so we can determine geometry permissions
      fetchLocations({ fields, perPage: 15000 })
      fetchLocationGroups({
        fields: '_id,name,type,search,locationCount',
        sort: 'name',
        perPage: 1000,
      })
    }

    // NOTE This is a crude way to batch load zones for an application. We will
    // in future add better support for lazy loading zones or handling faster
    // state updates which don't block JS
    if (hasLoadedAllZones) {
      console.debug('All zones loaded, skipping requests')
    } else {
      console.debug('Fetching all zones...')
      let fetchedAllZones = false
      let zonePage = 1

      while (fetchedAllZones === false) {
        const result = await fetchZones({
          appendToList: true,
          fields: ZONE_FIELDS,
          page: zonePage,
          perPage: 1000,
          disablePopulation: 1,
        })

        if (result.data.length < 1000) {
          fetchedAllZones = true
        } else {
          zonePage += 1
        }
      }
    }

    // kill any existing sockets and init with current app
    this.killSockets()
    this.initSockets()

    // every 5m refresh the users role.
    // This allows us to deal with the situation where the users permissions
    setInterval(this.props.roleRequest, 300000)

    // Google Analytics track page views
    // NOTE: reactGa initialised within src/setup
    history.listen(location => reactGa.pageview(location.pathname))
  }

  componentWillUnmount() {
    this.killSockets()
  }

  handleActivity(data, currentLocationId) {
    const { hasModulePermission, upsertActivity } = this.props

    const { location, type } = data
    const permissionModule = activityModule.getPermissionMapping(type)
    const hasPermission = !!hasModulePermission(permissionModule, 'read')

    const shouldSkip = !hasPermission || location !== currentLocationId

    if (shouldSkip) return

    return upsertActivity(data)
  }

  switchApplication(id) {
    const { history, roleRequest, setApplication } = this.props

    this.setState({ loading: true })

    setApplication(id)
      .then(() => roleRequest())
      .then(() => {
        history.push('/login')
      })
  }

  logout() {
    const { history } = this.props

    this.setState({ loading: true })

    set({ dimension1: undefined })
    setUserId(undefined)

    return history.push('/logout')
  }

  initSockets() {
    const { applicationId } = this.props
    const consumerId = this.getConsumerId()

    if (!consumerId) {
      logger.warn('Not initializing socket due to missing `consumerId`')
      return
    }

    const opts = {
      channel: [
        consumerId,
        `application-${applicationId}.activity`,
        `application-${applicationId}.areas`,
        `application-${applicationId}.issues`,
        `application-${applicationId}.taskEntries`,
      ],
      error: err => logger.error(err),
      callback: this.onSocketData,
    }

    console.info('⚡ Subscribing to channels', opts.channel)
    socket.subscribe(opts)
  }

  killSockets() {
    const channels = socket.get_subscribed_channels()

    console.info('⚡ Unsubscribing from all channels', channels)

    socket.unsubscribe({
      channel: channels,
    })
  }

  onSocketData(data) {
    const {
      currentLocationId,
      removeRoom,
      upsertArea,
      upsertIssue,
      upsertMessage,
      upsertRoom,
      upsertSpeaker,
      upsertTaskEntry,
    } = this.props

    const strategies = {
      activity: this.handleActivity,
      issues: upsertIssue,
      message: upsertMessage,
      room: upsertRoom,
      speaker: upsertSpeaker,
      taskEntries: upsertTaskEntry,
    }

    if (!data) {
      logger.warn('Missing data from socket message')
      return
    }

    const { type, entity } = data

    // NOTE: sockets will re-add the deleted entity to cache
    if (entity.deleted) {
      const isRoom = type === 'room'
      const action = isRoom ? removeRoom : noop

      return action(entity._id)
    }

    const fn = strategies[type] || noop

    fn(entity, currentLocationId)
  }

  getConsumerId() {
    return get(
      this.props.currentApplication,
      'application.speakerbox.consumerId'
    )
  }

  render() {
    return (
      <App
        {...this.props}
        loading={this.state.loading}
        logoutHandler={this.logout.bind(this)}
        switchApplicationHandler={this.switchApplication.bind(this)}
      >
        <Switch>
          <Route component={MapsRoute} exact path="/" />
          <Route component={SchedulesRoute} path="/schedules" />
          <Route component={SetupRoute} path="/setup" />
          <Route component={TemplatesRoute} path="/templates" />
          <Route component={ReportsRoute} path="/reports" />
          <Route component={MessagesRoute} path="/messages" />
          <Route component={SettingsRoute} path="/settings" />
          <Route component={AdminRoute} path="/admin" />
          <Route component={NotFoundRoute} path="/404" />
          <Redirect to="/404" />
        </Switch>
      </App>
    )
  }
}

export default compose(
  FlagsHOC,
  WithPermissions,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AppContainer)

function mapStateToProps(state) {
  const zonesList = state.zones.list['all']
  const zonesTotalCount = get(zonesList, 'pagination.totalCount')
  const zonesListCount = get(zonesList, 'items.length')
  const firstZoneId = get(zonesList, 'items[0]')
  const firstZone = get(state, `zones.cache.${firstZoneId}.entity`, {})

  // NOTE - if the first zone in the cache has no location, then the cache has
  // been invalidated. Around Sep '23, we added a new field to ZONE_FIELDS that
  // is required for zone filtering. Without it we needed to reload the zones,
  // so this acts as an invalidation hook
  const zonesCacheInvalidated = !firstZone.location

  if (zonesCacheInvalidated) {
    console.info('Zones cache is invalidated, zones will be reloaded')
  }

  const hasLoadedAllZones =
    !zonesCacheInvalidated &&
    zonesTotalCount &&
    parseInt(zonesListCount, 10) === parseInt(zonesTotalCount, 10)

  const locationsList = state.areas.list['all-locations']
  const locationsTotalCount = get(locationsList, 'pagination.totalCount')
  const locationsListCount = get(locationsList, 'items.length')
  const hasLoadedAllLocations =
    locationsTotalCount &&
    parseInt(locationsListCount, 10) === parseInt(locationsTotalCount, 10)

  const geometryPermissions = userApplicationsModule.geometryPermissions(state)
  return {
    applicationId: state.app.applicationId,
    authentication: state.authentication,
    currentApplication: userApplicationsModule.getCurrentApplication(state),
    currentLocationId: state.maps.current,
    currentUser: state.user.data,
    geometryPermissions,
    hasLoadedAllLocations,
    hasLoadedAllZones,
    region: state.app.region,
    userApplications: state.userApplications,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    clearAuthentication: () =>
      dispatch(authenticationModule.clearAuthentication()),
    fetchLocations: params =>
      dispatch(areaModule.getLocations(params, 'all-locations')),
    fetchLocationGroups: params =>
      dispatch(areaModule.getGroups('location-groups', params)),
    fetchZones: params => dispatch(zonesModule.query('all', params)),
    setApplication: (id, role) => dispatch(appModule.setApplication(id, role)),
    setEndpoints: endpoints => dispatch(appModule.setEndpoints(endpoints)),
    removeRoom: id => {
      dispatch(roomsModule.removeFromList('default', [id]))
      dispatch(roomsModule.removeFromCache(id))
    },
    roleRequest: () => dispatch(userModule.roleRequest()),
    unauthenticate: params =>
      dispatch(authenticationModule.unauthenticateRequest(params)),
    upsertArea: data => dispatch(areaModule.addToCache(data)),
    upsertRoom: data => dispatch(roomsModule.addToCache(data)),
    upsertSpeaker: data => dispatch(speakersModule.addToCache(data)),
    upsertMessage: data => dispatch(messagesModule.addToCache(data)),
    upsertIssue: data => dispatch(issueModule.addToCache(data)),
    upsertTaskEntry: data => dispatch(taskEntryModule.addToCache(data)),
    upsertActivity: (data = {}) => {
      const { _id, type } = data
      dispatch(activityModule.addToCache(data))
      dispatch(activityModule.addToList('all', [_id]))
      dispatch(activityModule.addToList(type, [_id]))
    },
  }
}
